import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'universal-cookie'
let cookies = new Cookies()

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: 'home', component: () => import('@/views/home.vue'), meta: { layout: 'full', }, },  
    { path: '/login', name: 'login', component: () => import('@/views/auth/login.vue'), meta: { layout: 'full', }, },  
    { path: '/register', name: 'register', component: () => import('@/views/auth/register.vue'), meta: { layout: 'full', }, },  
    
    { path: '/landing', name: 'landing', component: () => import('@/views/landing-page/index.vue'), meta: { layout: 'full', title: 'Landing' }, },  
    
    { path: '/homepage', name: 'homepage', component: () => import('@/views/homepage/index.vue'), meta: { layout: 'logged_in', title: 'Home' }, },  
    { path: '/homepage/services', name: 'homepage-services', component: () => import('@/views/homepage/services.vue'), meta: { layout: 'logged_in', title: 'Home' }, },  
    
    { path: '/profile', name: 'profile', component: () => import('@/views/profile/index.vue'), meta: { layout: 'logged_in', title: 'Profile' }, },  
    { path: '/profile/form', name: 'profile-form', component: () => import('@/views/profile/form.vue'), meta: { layout: 'logged_in', title: 'Profile' }, },  
    { path: '/profile/form-pet/:id?', name: 'profile-form-pet', component: () => import('@/views/profile/form-pet.vue'), meta: { layout: 'logged_in', title: 'Pet' }, },  
    
    { path: '/chats', name: 'chats', component: () => import('@/views/chats/index.vue'), meta: { layout: 'logged_in', title: 'Chats' }, },  
    { path: '/chats/:id', name: 'chats-body', component: () => import('@/views/chats/body.vue'), meta: { layout: 'full', title: 'Chats' }, },  
    
    { path: '/activities', name: 'activities', component: () => import('@/views/activities/index.vue'), meta: { layout: 'logged_in', title: 'Activities' }, },  
    { path: '/activities/pet', name: 'activities-pet', component: () => import('@/views/activities/pet.vue'), meta: { layout: 'logged_in', title: 'Activities' }, },  
    
    { path: '/settings', name: 'settings', component: () => import('@/views/settings/index.vue'), meta: { layout: 'logged_in', title: 'Settings' }, },  
    
    { path: '/training', name: 'training', component: () => import('@/views/training/index.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, },  
    { path: '/training/group', name: 'training-group', component: () => import('@/views/training/group.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, },  
    { path: '/training/individual/:id?', name: 'training-individual', component: () => import('@/views/training/individual.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, },  
    { path: '/training/book/:id', name: 'training-book', component: () => import('@/views/training/book.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, },  
    { path: '/training/payment/:id', name: 'training-payment', component: () => import('@/views/training/payment.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, },  
    { path: '/training/invoice/:id', name: 'training-invoice', component: () => import('@/views/training/invoice.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, },  
    
    { path: '/shelter', name: 'shelter', component: () => import('@/views/shelter/home.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/list', name: 'shelter-list', component: () => import('@/views/shelter/list.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/profile/:id', name: 'shelter-profile', component: () => import('@/views/shelter/profile.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    
    { path: '/shelter/volunteer/form/:shelter_id/:id?', name: 'shelter-volunteer-form', component: () => import('@/views/shelter/volunteer/form.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/volunteer/:id', name: 'shelter-volunteer', component: () => import('@/views/shelter/volunteer/index.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    
    { path: '/shelter/adopt/summary/:id', name: 'shelter-adopt-summary', component: () => import('@/views/shelter/adopt/form/summary.vue'), meta: { layout: 'logged_in', title: 'Adoption' }, }, 
    { path: '/shelter/adopt/form/:shelter_id/:id?', name: 'shelter-adopt-form', component: () => import('@/views/shelter/adopt/form.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/adopt/detail/:sha_id', name: 'shelter-adopt-detail', component: () => import('@/views/shelter/adopt/detail.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/adopt/invoice/:shelter_id', name: 'shelter-adopt-invoice', component: () => import('@/views/shelter/adopt/invoice.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/adopt/form-bus/:shelter_id/:id?', name: 'shelter-adopt-form-bus', component: () => import('@/views/shelter/adopt/form-bus.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/adopt/:id', name: 'shelter-adopt', component: () => import('@/views/shelter/adopt/index.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    
    { path: '/shelter/donate/form/:shelter_id/:id?', name: 'shelter-donate-form', component: () => import('@/views/shelter/donate/form.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/donate/payment', name: 'shelter-donate-payment', component: () => import('@/views/shelter/donate/payment.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/donate/invoice/:id', name: 'shelter-donate-invoice', component: () => import('@/views/shelter/donate/invoice.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/shelter/donate/:id', name: 'shelter-donate', component: () => import('@/views/shelter/donate/index.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
     
    { path: '/walker/list', name: 'walker-list', component: () => import('@/views/walker/list.vue'), meta: { layout: 'logged_in', title: 'Pet Sitting' }, }, 
    { path: '/walker/:id', name: 'walker', component: () => import('@/views/walker/index.vue'), meta: { layout: 'logged_in', title: 'Walker' }, }, 
    { path: '/walker/schedule/:id', name: 'walker-schedule', component: () => import('@/views/walker/schedule.vue'), meta: { layout: 'logged_in', title: 'Walker' }, }, 
    { path: '/walker/payment/:id', name: 'walker-payment', component: () => import('@/views/walker/payment.vue'), meta: { layout: 'logged_in', title: 'Walker' }, },  
    { path: '/walker/invoice/:id', name: 'walker-invoice', component: () => import('@/views/walker/invoice.vue'), meta: { layout: 'logged_in', title: 'Walker' }, },  
    
    { path: '/vet/home', name: 'vet-home', component: () => import('@/views/vet/home.vue'), meta: { layout: 'logged_in', title: 'Vet' }, }, 
    { path: '/vet/list', name: 'vet-list', component: () => import('@/views/vet/list.vue'), meta: { layout: 'logged_in', title: 'Vet' }, }, 
    { path: '/vet/profile/:id', name: 'vet-profile', component: () => import('@/views/vet/profile.vue'), meta: { layout: 'logged_in', title: 'Vet' }, }, 
    { path: '/vet/profile/:id/:user_id/status/:status?/:note?', name: 'vet-status', component: () => import('@/views/vet/status.vue'), meta: { layout: 'logged_in', title: 'Vet' }, }, 
    { path: '/vet/book-list/:vs_id', name: 'vet-book-list', component: () => import('@/views/vet/book-list.vue'), meta: { layout: 'logged_in', title: 'Vet' }, }, 

    { path: '/walker-register', name: 'walker-register', component: () => import('@/views/profile/bussiness/walker.vue'), meta: { layout: 'logged_in', title: 'Pet Sitting' }, }, 
    { path: '/trainer-register', name: 'trainer-register', component: () => import('@/views/profile/bussiness/trainer.vue'), meta: { layout: 'logged_in', title: 'Trainer' }, }, 
    { path: '/vet-register', name: 'vet-register', component: () => import('@/views/profile/bussiness/vet.vue'), meta: { layout: 'logged_in', title: 'Vet' }, }, 
    { path: '/shelter-register', name: 'shelter-register', component: () => import('@/views/profile/bussiness/shelter.vue'), meta: { layout: 'logged_in', title: 'Shelter' }, }, 
    { path: '/store-register', name: 'store-register', component: () => import('@/views/profile/bussiness/pet_shop'), meta: { layout: 'logged_in', title: 'Home' }, },

    { path: '/bussiness/walker/:id', name: 'bussiness-walker', component: () => import('@/views/walker/profile.vue'), meta: { layout: 'logged_in', title: 'Pet Sitting' }, },
    { path: '/bussiness/walker-book-detail/:id', name: 'bussiness-walker', component: () => import('@/views/walker/book-detail.vue'), meta: { layout: 'logged_in', title: 'Pet Sitting' }, },

    { path: '/bussiness', name: 'bussiness', component: () => import('@/views/bussiness/home.vue'), meta: { layout: 'logged_in', title: 'Home' }, }, 

    { path: '/bussiness/shop', name: 'bussiness-shop', component: () => import('@/views/bussiness/shop/index.vue'), meta: { layout: 'logged_in', title: 'Home' }, },
    { path: '/bussiness/shop/product/:id?', name: 'bussiness-shop-form', component: () => import('@/views/bussiness/shop/form.vue'), meta: { layout: 'logged_in', title: 'Home' }, },
    { path: '/bussiness/shop/product/:id/detail', name: 'bussiness-shop-detail', component: () => import('@/views/bussiness/shop/detail.vue'), meta: { layout: 'logged_in', title: 'Home' }, },
    
    { path: '/shop/products', name: 'shop-products', component: () => import('@/views/shop/products.vue'), meta: { layout: 'logged_in', title: 'Pet Shop' }, },
    { path: '/shop/stores', name: 'shop-products', component: () => import('@/views/shop/store.vue'), meta: { layout: 'logged_in', title: 'Pet Shop' }, },

    { path: '/bussiness/training', name: 'bussiness-training', component: () => import('@/views/bussiness/training/index.vue'), meta: { layout: 'logged_in', title: 'Home' }, }, 
    { path: '/bussiness/training/form/:id?', name: 'bussiness-training-form', component: () => import('@/views/bussiness/training/form.vue'), meta: { layout: 'logged_in', title: 'Home' }, }, 
    { path: '/bussiness/training/form/:id/schedules', name: 'bussiness-training-schedules', component: () => import('@/views/bussiness/training/schedules.vue'), meta: { layout: 'logged_in', title: 'Home' }, }, 


    { path: '*', name: 'error-404', component: () => import('@/views/error/Error404.vue'),  meta: {  layout: 'full',  },  },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((item) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
 
  if(item.name != 'landing' && item.name != 'register'){
    if(!cookies.get('token')){
      router.push('/login')
    }
  } 
  
  if(cookies.get('token') && item.name == 'home'){
    setTimeout(() => {
      router.push('/login')
    }, 1000)
  }

})

export default router
